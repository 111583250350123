<template>
  <div style="display: inline;">
    <el-button type="text" @click="dialogVisible = true">
      <slot></slot>
    </el-button>

    <el-dialog
        custom-class="elDialog"
        title="分享上传链接"
        center
        :visible.sync="dialogVisible"
        width="600px">
      <el-form :hide-required-asterisk="true" ref="form" :model="form" label-width="80px"  :rules="rules"  label-position="left">
        <el-form-item label="有效期">{{ validDay }}天</el-form-item>
        <el-form-item  label="用户手机" prop="phone">
          <el-input   maxlength="11" v-model="form.phone" placeholder="请输入上传文件的用户手机号" style="width: 308px"></el-input>
        </el-form-item>
        <el-form-item label="链接">
          <el-input v-model="form.share_url" placeholder="请将生成的链接分享给上传文件的人" style="width: 308px" readonly></el-input>
          <el-button
              type="primary" style="margin-left: 16px;border-radius: 3px"
              :data-clipboard-text="form.share_url"
              class="copy"
              @click="$popup({msg:'复制成功',type:'success'})"
              v-if="!!form.share_url">复制链接</el-button>
          <el-button
              type="primary" style="margin-left: 16px;border-radius: 3px"
              @click="shareCreate"
              v-else>生成链接</el-button>
        </el-form-item>
        <el-form-item v-if="!!form.share_url">
          <div style="display: flex;align-items: center;justify-content: space-between;font-size: 12px;background-color: #f7f7f7;border-radius: 4px;padding: 0 14px;width: 420px">
            <div style="color: #999999">
              链接剩余有效时间：<span style="color: #FF004D">{{ time }}</span>
            </div>
            <div style="color: #666666;display: flex;align-items: center;cursor: pointer" @click="shareCreate">
              <img src="../../../../assets/share-refresh.png" alt="refresh" style="width: 20px;height: 20px;margin-right: 7px">
              重新生成
            </div>
          </div>
        </el-form-item>
        <el-form-item >
          <el-button @click="dialogVisible = false" style="width: 306px;height: 42px;margin-top:40px;">关闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {getTrackShare, shareCreate} from "@/api/issue"
import ClipboardJS from "clipboard"
import moment from "moment"
let timer = null;
export default {
  name: "shareLink",

  props: {
    id: Number
  },

  data(){
    return {
      dialogVisible: false,
      form: {
        phone: '',
        share_url: ''
      },
      // 有效期
      validDay: undefined,
      // 过期时间
      expireTime: undefined,
      // 剩余有效时间
      time: undefined,
      rules:{
        phone: {required:true, pattern:/^1[3456789]\d{9}$/,message:'请输入正确的手机号',trigger:'change,blur'}
      }
    }
  },

  mounted() {

    new ClipboardJS('.copy');
  },

  methods: {
    timerFn(){
      clearInterval(timer)
      moment.locale('zh-cn')
       timer = setInterval(() => {
        let duration = moment(this.expireTime) - moment()
        if (duration < 0) {
          duration = 0
          clearInterval(timer)
        }
        const time = moment.duration(duration, 'milliseconds')

        this.time = ''
        if (time.days()) {
          this.time += time.days() + '天' + time.hours() + '小时'+ time.minutes() + '分钟' + time.seconds() + '秒'
        } else if (time.hours()) {
          this.time += time.hours() + '小时' + time.minutes() + '分钟' + time.seconds() + '秒'
        } else if (time.minutes()) {
          this.time += time.minutes() + '分钟' + time.seconds() + '秒'
        } else if (time.seconds()) {
          this.time += time.seconds() + '秒'
        } else {
          this.time = '已过期'
        }

        duration -= 1000
      }, 1000)
    },
    getTrackShare(id) {
      getTrackShare(id).then(r => {
        if (r.code === 0){
          this.validDay = r.data.config.valid_day
          this.form.share_url = r.data.share_url
          this.form.phone = r.data.phone
          this.expireTime = r.data.expire_time
          this.timerFn();
        }
      })
    },
    shareCreate(){
      this.$refs.form.validate((callback)=>{
        if(!callback) return
        shareCreate({
          track_id: this.id,
          phone: this.form.phone,
          from: 1
        }).then(r => {
          if (r.code === 0){
            this.form.share_url = r.data.url
            this.expireTime = r.data.expire_time
            this.timerFn();
            this.$popup({msg: '生成链接',type:'success'})
          }
        })
      })
    }
  },

  watch: {
    dialogVisible(val) {
      if (val){
        this.getTrackShare(this.id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.elDialog){
  border-radius: 10px;
}
:deep(.el-form-item__label){
  color: #4f4f4f;
  font-size: 14px;
}
:deep(.el-input__inner){
  border: 1px solid #eeeeee;
  border-radius: 4px;
  line-height: 36px;
}
:deep(.el-input){
  line-height: 36px;
}
:deep(.el-dialog__header){
  padding-top: 30px;
  .el-dialog__title{
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #222222;
  }
}
:deep(.el-dialog__body){
  padding: 30px 45px 48px 45px;
}
:deep(.el-dialog__footer){
  padding-bottom: 48px;
}
</style>
